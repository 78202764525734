import React from 'react';
import { useMediaQuery } from '@mui/material';
import { globalConstant } from '../../globalConstants';
import Heading from './Heading';
import { useNavigate } from 'react-router-dom';

const cities = [
    { title: "Bangalore", description: "Ensure your home stays spotless and everything runs smoothly in the vibrant city of Bangalore. Our skilled professionals are committed to maintaining the highest standards for a clean and efficient household.", imgSrc: "/images/bangalore.jpg" },
    { title: "Hyderabad", description: "Keep your home secure and well-maintained in Hyderabad. Our well-trained experts provide exceptional cleaning and security services, ensuring your space remains pristine and protected.", imgSrc: "/images/hyderbad.jpg" },
    { title: "Mumbai", description: "Enjoy a clean and healthy environment in the bustling city of Mumbai. Our dedicated team guarantees spotless spaces and ensures everyone's well-being with top-tier hygiene services.", imgSrc: "/images/mumbai.jpg" },
    { title: "Chennai", description: "Experience seamless service delivery in Chennai, where our expert team ensures your home remains well-maintained and comfortable. From cleaning to repairs, we cover all aspects of home care, so you can enjoy a worry-free living environment.", imgSrc: "/images/chennai.jpg" },
    { title: "Gurgaon", description: "In the bustling city of Gurgaon, we provide top-notch services that guarantee cleanliness, efficiency, and security. Our professionals are dedicated to maintaining your home’s aesthetics and functionality, allowing you to focus on what matters most.", imgSrc: "/images/gurgaon.jpg" },
    { title: "Pune", description: "Discover dependable home services in Pune, where our skilled team is committed to ensuring your space remains clean, organized, and running efficiently. Enjoy the peace of mind that comes with our comprehensive approach to home maintenance.", imgSrc: "/images/pune.jpg" },
];

const ServicableCities = () => {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { primaryColor } = globalConstant()

    const styles = {
        container: {
            padding: 0,
            textAlign: 'center',
        },
        heading: {
            color: '#41A317',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        subheading: {
            width: isDesktop ? '60%' : '90%',
            margin: 'auto',
            marginBottom: isDesktop ? 0 : '10%',
            fontSize: 18,
            marginBottom: '20px',
            color: '#555',
        },
        grid: {
            display: 'grid',
            marginTop: isDesktop ? '5%' : '',
            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : isTablet ? 'repeat(2, 1fr)' : '1fr',
        },
        card: {
            overflow: 'hidden',
            height: 450,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            background: '#fff',
            borderRadius: 0,
            cursor: 'pointer',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        cardContent: {
            padding: isDesktop ? '20% 5% 5%' : '15px',
            textAlign: 'left',
            background: 'linear-gradient(180deg, #163F1800 , #163F18 90%',
            color: '#fff',
            position: 'absolute',
            bottom: 0,
            width: '100%',
        },
        title: {
            fontSize: isDesktop ? 27 : 22,
            fontWeight: '600',
        },
        description: {
            fontSize: 16,
            width: isDesktop ? '80%' : '',
            marginTop: '5px',
        }
    };

    return (
        <>
            <div style={styles.container}>
                <Heading title={'Proud to be Serviceable in'} color={primaryColor} />
                <p style={styles.subheading}>
                    Experience top-quality services at unbeatable prices across India's thriving urban centers. We're dedicated to keeping your spaces immaculate, secure, and running smoothly.
                </p>
                <div style={styles.grid}>
                    {cities.map((service, index) => (
                        <div style={styles.card} key={index} onClick={() => navigate(`gallery/${service?.title}`)}>
                            <img src={service.imgSrc} alt={service.title} style={styles.image} />
                            <div style={styles.cardContent}>
                                <h3 style={styles.title}>{service.title}</h3>
                                <p style={styles.description}>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ServicableCities;
