import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MyAccount from './MyAccount';
import MyBookings from './MyBookings';
import Billings from './Billings';
import Header from '../../../website/Components/Header';
import Footer from '../../../website/Components/Footer';
import { globalConstant } from '../../../globalConstants';
import HelpCenter from './HelpCenter';
import OnSiteBooking from './OnSiteBooking';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RateUs from './RateUs';
import SocialLinks from '../../../website/Components/SocialLinks';

const UserDashboard = ({ socket }) => {

    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const location = useLocation();
    const tab = location?.state?.tab;

    const [value, setValue] = useState(tab || 0);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');
    const { primaryColor } = globalConstant();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: isMobile || isTablet ? 0 : 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const theme = createTheme({
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        minWidth: isMobile ? 70 : isTablet ? 100 : 120,
                        color: 'black', // Text color for the tabs
                        '&.Mui-selected': {
                            color: 'white', // Text color for the selected tab
                            backgroundColor: primaryColor, // Background color for the selected tab
                            borderRadius: '10px', // Rounded borders for the selected tab
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        display: 'none', // Hide the default indicator
                    },
                },
            },
        },
    });

    return (
        <>
            <SocialLinks />
            <Header />
            <div style={{ padding: '3% 0' }} className='user-dashboard'>
                <ThemeProvider theme={theme}>
                    <Box sx={{ width: '100%', padding: 0 }}>
                        <Tabs
                            style={{
                                border: '1px solid ' + primaryColor,
                                background: 'white',
                                margin: 'auto',
                                padding: '5px',
                                borderRadius: 10,
                                overflowX: 'auto', // Ensure tabs can scroll horizontally
                                maxWidth: isMobile || isTablet ? '100%' : 800, // Prevent overflow beyond viewport width
                                whiteSpace: 'nowrap' // Prevent tabs from wrapping to the next line
                            }}
                            value={value}
                            onChange={handleChange}
                            variant={isMobile ? "scrollable" : "standard"}
                            scrollButtons="auto"
                            aria-label="tabs"
                            centered={!isMobile}
                        >
                            <Tab label="My Account" />
                            <Tab label="My Bookings" />
                            <Tab label="Membership" />
                            <Tab label="Rate Us" />
                            <Tab label="Help Center" />
                            <Tab label="On Site Booking" />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <MyAccount user_data={user_data} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <MyBookings user_data={user_data} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Billings user_data={user_data} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <RateUs user_data={user_data} />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <HelpCenter user_data={user_data} />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            <OnSiteBooking user_data={user_data} socket={socket} />
                        </TabPanel>
                    </Box>
                </ThemeProvider>
            </div>
            <Footer />
        </>
    );
};

export default UserDashboard;
