import { Rating, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { get_data } from '../../api';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const Testimonials = () => {
    const isDesktop = useMediaQuery('(min-width: 768px)');

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: '' }}
                onClick={onClick}
            >
                <IoIosArrowForward style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <IoIosArrowBack style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 150,
        autoplay: false,
        slidesToShow: isDesktop ? 4 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };


    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    const getAllTestimonials = async () => {
        try {
            const data = await get_data(`testimonials/get-all-testimonials?pageNumber=${1}`);
            if (data.status) {
                setLoader(false);
                setData(data?.data?.testimonials);
            }
        } catch (error) {
            setLoader(false);
        }
    }

    useEffect(() => {
        getAllTestimonials();
    }, []);

    function showSlider() {
        return data?.map((testimonial, index) => {
            return (
                <div>
                    <div className="testimonial" key={index} style={{width:'95%'}}>
                        <div style={{ marginBottom: '3%' }}>
                            <FaUserCircle
                                style={{
                                    color: 'gainsboro',
                                    height: 40,
                                    width: 40,
                                    textAlign: 'left'
                                }}
                            />
                        </div>
                        <div className="testimonial-content">
                            <p className="testimonial-name">{testimonial?.name}</p>
                            <p className="testimonial-text">"{testimonial?.review}"</p>
                            <Rating precision={0.5} value={testimonial?.rating} readOnly />
                            {/* <p className="testimonial-service">{testimonial.service}</p> */}
                        </div>
                    </div>
                </div>
            );
        });
    }


    return (
        <div className="testimonials-section">
            <h2 style={{ fontWeight: 600, color: '#000', fontSize: isDesktop ? 35 : 24, }}>Our customers are living life with a <span className="highlight">smile on their faces!</span></h2>
            <div style={{ width: "90%", margin: "auto" }}>
                <Slider {...settings}>
                    {showSlider()}
                </Slider>
            </div>
            <a target='_blank' href="https://www.google.com/search?q=Nivishka+Services&num=10&sca_esv=8963e2b334c09b73&sxsrf=ADLYWIJ08BVj2jjVoHOBjl5bHb2hLP65qQ%3A1729299672514&ei=2AQTZ_OQH_-94-EP99bTuAg&ved=&uact=5&oq=Nivishka+Services&gs_lp=Egxnd3Mtd2l6LXNlcnAiEU5pdmlzaGthIFNlcnZpY2VzMgcQIxiwAxgnMgcQIxiwAxgnMg0QABiABBiwAxhDGIoFMg4QLhiABBiwAxjHARivATIIEAAYgAQYsAMyCBAAGIAEGLADMgsQABiABBiwAxiiBDILEAAYgAQYsAMYogQyCxAAGIAEGLADGKIEMgsQABiABBiwAxiiBEi-AlAAWABwAXgAkAEAmAHgAaAB4AGqAQMyLTG4AQPIAQCYAgGgAgaYAwCIBgGQBgqSBwExoAfNCg&sclient=gws-wiz-serp"
                className="reviews-link">Check all Reviews</a>
        </div>
    );
};

export default Testimonials;