import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import CustomerTable from "../Components/CustomerTable";
import { get_data, post_data } from "../../../api";
import SearchComponent from "../Components/SearchComponent";
import ExportModal from "../Components/ExportModal";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../globalConstants";

export default function Customers({ type }) {

    const [loading, setLoading] = useState("true")
    const [customerData, setCustomerData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false)
    const itemsPerPage = 15;
    const [totalPages, setTotalPages] = useState(null);
    const [totalCustomers, setTotalCustomers] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchState, setSearchState] = useState(false)
    const [addCustomer, setAddCustomer] = useState(false)


    const handleSearch = () => {
        setLoader(true)
        setTimeout(() => {
            if (value !== '') {
                searchCustomers()
                setSearchState(true)
            }
            else {
                getAllCustomer()
                setSearchState(false)
            }
        }, 500);
    }

    const searchCustomers = async () => {
        try {
            const data = await post_data(`customer/search-customers/${value}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setCustomerData(data?.data?.customer);
                setTotalPages(data?.data?.totalPages);
                setTotalCustomers(data?.data?.totalCustomers);
            }
            else {
                setLoader(false);
                setCustomerData([])
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error While Fetching All Customers", error);
        }
    }

    const getAllCustomer = async () => {
        try {
            const data = await get_data(`customer/get-all-customer?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setCustomerData(data?.data?.customer);
                setTotalPages(data?.data?.totalPages);
                setTotalCustomers(data?.data?.totalCustomers);
            }
        } catch (error) {
            setLoader(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchCustomers()
            }
            else {
                getAllCustomer();
            }
        }
    }, [currentPage])


    const customer_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Phone", grid: 2 },
        { title: "Name", grid: 2 },
        { title: "Email", grid: 2 },
        { title: "City", grid: 2 },
        { title: "Active", grid: 2 },
        { title: "Action", grid: 1 },

    ];

    const count = customerData?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...customer_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Customers' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setAddCustomer(true)}>
                        Create
                    </Button>
                    <Button variant="contained" style={{ borderRadius: 7, background: 'black', boxShadow: 'none' }} onClick={() => setOpen(true)}>
                        Export
                    </Button>
                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>

                <div style={page_container}>
                    <CustomerTable
                        tableHeader={tableHeader}
                        data={customerData}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        setLoader={setLoader}
                        addCustomer={addCustomer}
                        setAddCustomer={setAddCustomer}
                        loader={loader}
                        totalCustomers={totalCustomers}
                        getAllCustomer={getAllCustomer}
                    />
                </div>
                <ExportModal isOpen={open} setOpen={setOpen} type='customers' />
            </Grid>
        </>
    )
}