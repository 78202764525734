import React, { useState } from 'react';
import { Card, CardContent, Typography, Avatar, Box, Rating, Pagination } from '@mui/material';
import { styled } from '@mui/system';
import { endPointURL, post_data } from '../../../api';
import { MdDelete } from "react-icons/md";
import Swal from 'sweetalert2';
import { MdEdit } from "react-icons/md";
import AddTestimonials from './AddTestimonials';

const AllTestimonials = ({
    data,
    currentPage,
    totalPages,
    totalTestimonials,
    itemsPerPage,
    count,
    getAllTestimonials,
    setCurrentPage,
    setLoader
}) => {


    const [selectedTestimonial, setSelectedTestimonial] = useState({})
    const [open, setOpen] = useState(false)

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const GridContainer = styled('div')({
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '16px',
        padding: '16px',
    });

    const ReviewCard = styled(Card)({
        borderRadius: '6px',
        boxShadow: 'none',
        border: '1px solid gainsboro',
        margin: 0,
    });

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalTestimonials);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };


    const handleDelete = async (id) => {
        Swal.fire({
            title: "Do you want to Delete?",
            showDenyButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#5DB761",
            denyButtonText: "Cancel"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = await post_data(`testimonials/delete-testimonial/${id}`)
                if (data?.status) {
                    getAllTestimonials();
                    Toast.fire({
                        icon: "success",
                        text: "Testimonial Deleted!",
                        color: "Green"
                    })
                } else {
                    Toast.fire({
                        icon: "error",
                        text: "Something Went Wrong",
                        color: "Red"
                    })
                }
            }
        });
    }

    const handleEdit = (review) => {
        setSelectedTestimonial(review)
        setOpen(true)
    }

    return (
        <>
            <GridContainer>
                <AddTestimonials
                    open={open}
                    setOpen={setOpen}
                    isUpdate={true}
                    selectedTestimonial={selectedTestimonial}
                    getAllTestimonials={getAllTestimonials}
                />

                {data?.map((review) => (
                    <ReviewCard key={review?._id} style={{ position: 'relative' }}>
                        <MdEdit
                            onClick={() => handleEdit(review)}
                            style={{ position: 'absolute', right: '14%', top: '3%', fontSize: 25, color: 'black', cursor: 'pointer' }} />
                        <MdDelete
                            onClick={() => handleDelete(review?._id)}
                            style={{ position: 'absolute', right: '3%', top: '3%', fontSize: 25, color: 'red', cursor: 'pointer' }} />
                        <CardContent>
                            <Box display="flex" alignItems="start" mb={2}>
                                <Box ml={0}>
                                    <Typography variant="h6" style={{ fontSize: 17 }}>{review?.name || 'User'}</Typography>
                                    <p style={{ fontSize: 14, margin: '3% 0' }}>{review?.review}</p>
                                    <Rating precision={0.5} value={review?.rating} readOnly />
                                </Box>
                            </Box>
                            <Typography variant="body1">{review?.message}</Typography>
                        </CardContent>
                    </ReviewCard>
                ))}
            </GridContainer>
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${count} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </>
    );
};

export default AllTestimonials;
