import React from 'react';
import { Card, CardContent, Typography, Box, IconButton } from '@mui/material';
import { MdModeEdit, MdOutlineDeleteOutline } from "react-icons/md";
import { endPointURL } from "../../../api";

const AdminGalleryCard = ({ gallery, onEdit, onDelete }) => {

    return (
        <div style={{ maxWidth: 350, height: '100%' }}>
            <Box>
                <img
                    style={{
                        width: '100%',
                        height: 200,
                        objectFit: 'cover', // Ensures image fits the container without distortion
                        borderRadius: 5, // Adds a border radius to the image
                    }}
                    src={`${endPointURL}/uploads/city-gallery-picture/${gallery?.image}`}
                    alt={gallery?.city_name} // Adds alt text for accessibility
                />
            </Box>
            <CardContent sx={{ padding: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Typography variant="h6" component="div" style={{ fontWeight: 400, fontSize: 18, color: 'gray', margin: 0 }}>
                            {gallery?.city_name}
                        </Typography>
                    </div>
                    <div style={{ gap: 10, display: 'flex' }}>
                        <IconButton onClick={onEdit} color="primary">
                            <MdModeEdit style={{ fontSize: 25 }} />
                        </IconButton>
                        <IconButton onClick={onDelete} color="error">
                            <MdOutlineDeleteOutline style={{ fontSize: 25 }} />
                        </IconButton>
                    </div>
                </div>
            </CardContent>
        </div>
    );
};

export default AdminGalleryCard;
