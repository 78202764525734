import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../website/Components/Header';
import Footer from '../../website/Components/Footer';
import { endPointURL, get_data } from '../../api';
import { useParams } from 'react-router-dom';
import { Pagination, Typography } from '@mui/material';
import Empty from '../../web-app/user-dashboard/Components/Empty';

const Gallery = () => {
    const [hovered, setHovered] = useState(null);
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const { city } = useParams();
    const [totalData, setTotalData] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: matches_md ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)',
        gap: '30px', // Adjust spacing between columns and rows
    };

    const columnStyle = (isHovered) => ({
        borderRadius: '8px', // Adjust border radius as needed
        overflow: 'hidden', // Ensures border-radius is applied correctly
        textAlign: 'center',
        cursor: 'pointer',
        padding: '16px', // Adjust padding as needed
        transform: isHovered ? 'scale(1.1)' : 'scale(1)', // Zoom effect on hover
        transition: 'transform 0.3s ease-in-out', // Smooth transition effect
    });

    const imageStyle = {
        height: 400,
        width: '100%',
        borderRadius: 10
    };

    const titleStyle = {
        marginTop: '8px',
        fontSize: '18px',
        fontWeight: 500,
    };


    const getAllGallery = async () => {
        try {
            if (city) {
                const data = await get_data(`city_gallery/get-city-gallery-by-city/${city}`);
                if (data.status) {
                    setLoader(false);
                    setData(data?.data?.cityGallery);
                    setTotalData(data?.data?.totalGallery);
                    setTotalPages(data?.data?.totalPages);
                }
            } else {
                const data = await get_data(`city_gallery/get-all-city-gallery`);
                if (data.status) {
                    setLoader(false);
                    setData(data?.data?.cityGallery);
                    setTotalData(data?.data?.totalGallery);
                    setTotalPages(data?.data?.totalPages);
                }
            }
        } catch (error) {
            setLoader(false);
        }
    }
    useEffect(() => {
        getAllGallery()
    }, [])

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const startEntry = (currentPage - 1) * 15 + 1;
    const endEntry = Math.min(currentPage * 15, totalData);

    if (data?.length === 0 && loader === false) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10% 0' }}>
                <Empty />
            </div>
        )
    }

    return (
        <>
            <Header />
            {
                loader ?
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '10% 0' }}>
                            <CircularProgress />
                        </div>
                    </>
                    :
                    <>
                        <div style={{ padding: matches_md ? '20% 7% 7%' : '10%' }} className='blog-page'>
                            <h2 style={{ fontWeight: 600, fontSize: 35, color: 'black', marginBottom: '3%' }}>Our Gallery</h2>
                            <div style={gridStyle}>
                                {data?.map((item, index) => (
                                    <div
                                        key={index}
                                        style={columnStyle(hovered === index)}
                                        onMouseEnter={() => setHovered(index)}
                                        onMouseLeave={() => setHovered(null)}
                                    >
                                        <img
                                            src={`${endPointURL}/uploads/gallery-picture/${item?.image}`}
                                            alt={`${item?.city_name} image`}
                                            style={imageStyle}
                                        />
                                        <h2 style={titleStyle}>{item?.city_name}</h2>
                                    </div>
                                ))}
                            </div>
                        </div>


                        {data?.length > 0 && (
                            <div
                                style={{
                                    borderTop: "1px dashed gainsboro",
                                    paddingTop: "2%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "90%",
                                    margin: "auto",
                                    marginBottom: "3%",
                                }}
                            >
                                <Typography
                                    style={{
                                        fontWeight: 500,
                                        fontSize: 15,
                                        color: "black",
                                    }}
                                >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </div>
                        )}

                    </>
            }
            <Footer />
        </>
    );
};

export default Gallery;