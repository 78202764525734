// SocialLinks.js
import { Facebook, Instagram, YouTube } from '@mui/icons-material';
import React from 'react';

const SocialLinks = () => {
  return (
    <div className="social-links">
      <a href="https://www.facebook.com/nivishka.in/" target="_blank" rel="noopener noreferrer" className="social-link">
        <Facebook />
      </a>
      <a href="https://www.youtube.com/nivishka-services/" target="_blank" rel="noopener noreferrer" className="social-link">
        <YouTube />
      </a>
      <a href="https://www.instagram.com/nivishkaservices/" target="_blank" rel="noopener noreferrer" className="social-link">
        <Instagram />
      </a>
    </div>
  );
};

export default SocialLinks;
