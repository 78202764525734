import { globalConstant } from "../../globalConstants";
import Heading from "./Heading";
import { useMediaQuery } from '@mui/material';

export default function HassleFreeService() {
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { primaryColor } = globalConstant();

    const styles = {
        container: {
            padding: isDesktop ? '3% 10%' : '20px',
            textAlign: 'center',
        },
        heading: {
            color: '#41A317',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        subheading: {
            fontSize: '18px',
            marginBottom: '20px',
            color: '#555',
        },
        grid: {
            display: 'grid',
            gap: '5px',
            marginTop: '20px',
            gridTemplateColumns: isDesktop
                ? 'repeat(4, 1fr)'
                : isTablet
                    ? 'repeat(3, 1fr)'
                    : 'repeat(2, 1fr)',
        },
        card: {
            position: 'relative',
            background: '#fff',
            borderRadius: '10px',
            overflow: 'hidden',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px',
            // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease',
        },
        cardHover: {
            transform: 'scale(1.05)',
        },
        image: {
            width: '50%',
            height: 'auto',
            objectFit: 'contain',
            marginBottom: '10px',
        },
        cardContent: {
            textAlign: 'center',
        },
        title: {
            fontSize: '16px',
            color: '#555',
            // fontWeight: 'bold',
            // marginTop: '10px',
        },
    };

    const ServiceIcons = [
        { icons: '/images/icons/clean.png', title: 'Cleaning' },
        { icons: '/images/icons/painting.png', title: 'Painting' },
        { icons: '/images/icons/pest control.png', title: 'Pest Control' },
        { icons: '/images/icons/moving.png', title: 'Packers & Movers' },
        { icons: '/images/icons/marble polishing.png', title: 'Marble Polishing' },
        { icons: '/images/icons/water proofing.png', title: 'Water Proofing' },
        { icons: '/images/icons/ac.png', title: 'AC & Appliance Repair' },
        { icons: '/images/icons/plumbing.png', title: 'Plumbing' },
        { icons: '/images/icons/civil.png', title: 'Civil & Interior' },
        { icons: '/images/icons/landscaping.png', title: 'Gardening & Landscaping' },
        { icons: '/images/icons/security.png', title: 'Security Service' },
        { icons: '/images/icons/housekeeping.png', title: 'Housekeeping' },
    ];

    return (
        <div style={styles.container}>
            <Heading title={'Hassle Free Service'} color={primaryColor} />
            <p style={styles.subheading}>For Homes and Workplaces Looking for a Service</p>
            <div style={styles.grid}>
                {ServiceIcons?.map((service, index) => (
                    <div
                        key={index}
                        style={styles.card}
                        className="card-hover"
                    >
                        <img
                            src={service?.icons}
                            alt={service?.title}
                            style={styles.image}
                        />
                        <div style={styles.cardContent}>
                            <p style={styles.title}>{service?.title || 'Description for service'}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
