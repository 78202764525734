import { useEffect, useState } from "react";
import { Card, Grid, Modal, Box, TextField, Button, Typography, Pagination } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import Swal from 'sweetalert2';
import { globalConstant } from "../../../globalConstants";
import { endPointURL, get_data, post_data } from "../../../api";
import DashboardHeading from "../Components/DashboardHeading";
import SubServicesSkeleton from "../../../website/Components/Skeleton/SubServicesSkeleton";
import AdminGalleryCard from "../Components/AdminGalleryCard";

export default function AdminGallery() {

    const { primaryColor } = globalConstant();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editGalleryId, setEditGalleryId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [formValues, setFormValues] = useState({
        city_name: '',
        image: '',
        file: null
    });

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const [errors, setErrors] = useState({
        image: ''
    });

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const gallery_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const handleClick = () => {
        setOpen(true);
        setIsEdit(false);
        setFormValues({ city_name: '', image: '', file: null });
    };

    const handleClose = () => {
        setOpen(false);
        setFormValues({ city_name: '', image: '', file: null });
        setErrors({ image: '' });
        setIsEdit(false);
        setEditGalleryId(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormValues({ ...formValues, image: URL.createObjectURL(file), file: file });
            setErrors({ ...errors, image: '' });
        }
    };
    const validateForm = () => {
        let isValid = true;
        const newErrors = { image: '' };

        if (!formValues.image && !formValues.file && !isEdit) {
            newErrors.image = 'Please upload an image.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                const formdata = new FormData();
                formdata.append("city_name", formValues?.city_name);
                if (formValues.file) {
                    formdata.append("image", formValues?.file);
                }

                let data;
                if (isEdit) {
                    if (formValues.file) {
                        data = await post_data(`city_gallery/update-city-gallery-image/${editGalleryId}`, formdata);
                    }
                    else {
                        data = await post_data(`city_gallery/update-city-gallery/${editGalleryId}`, { city_name: formValues?.city_name })
                    }
                } else {
                    data = await post_data("city_gallery/create-city-gallery", formdata);
                }
                // console.log("FFFFFFFFF", data)
                if (data.status === true) {
                    handleClose();
                    getAllGallery();
                    Toast.fire({
                        icon: 'success',
                        title: isEdit ? "Gallery Updated Successfully" : "Gallery Added Successfully",
                        color: "green"
                    });
                } else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "green"
                    });
                }
            } catch (error) {
                handleClose();
            }
        }
    };

    const handleEditClick = (gallery) => {
        setFormValues({
            city_name: gallery?.city_name,
            image: `${endPointURL}/uploads/city-gallery-picture/${gallery?.image}`,
            file: null
        });
        setEditGalleryId(gallery?._id);
        setIsEdit(true);
        setOpen(true);
    };

    const handleDeleteClick = (galleryId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`city_gallery/delete-city-gallery/${galleryId}`);
                    if (data.status) {
                        getAllGallery();
                        Toast.fire({
                            icon: 'success',
                            title: 'Gallery Deleted Successfully',
                            color: "green"
                        });
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something Went Wrong",
                            color: "green"
                        });
                    }
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Failed to delete the gallery',
                        color: "red"
                    });
                }
            }
        });
    };

    const getAllGallery = async () => {
        try {
            const data = await get_data(`city_gallery/get-all-city-gallery`);
            if (data.status === true) {
                setLoader(false);
                setData(data?.data?.cityGallery || []);
                setTotalData(data?.data?.totalGallery || 0);
                setTotalPages(data?.data?.totalPages || 0);
            }
        } catch (error) {
            setLoader(false);
        }
    };

    useEffect(() => {
        getAllGallery();
    }, []);

    const startEntry = (currentPage - 1) * 15 + 1;
    const endEntry = Math.min(currentPage * 15, totalData);


    return (
        <>
            <Grid container spacing={0} style={{ ...gallery_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Gallery' />
                {
                    loader ?
                        <>
                            <SubServicesSkeleton />
                        </>
                        :
                        <>
                            <div style={page_container}>
                                <div style={{ display: 'flex', gap: 30 }}>
                                    <Grid container spacing={3}>
                                        <Grid item md={3}>
                                            <div onClick={handleClick} style={{
                                                width: 280, height: '100%', borderRadius: 2, cursor: 'pointer',
                                                border: '1px dashed gray', padding: 0, boxShadow: 'none',
                                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>
                                                <IoMdAdd style={{ fontSize: 60 }} />
                                            </div>
                                        </Grid>
                                        {
                                            data?.length === 0 ? <></> : <> {data?.map((item, i) => (
                                                <Grid item md={3} key={i}>
                                                    <div style={{ borderRadius: 5, border: '1px solid #e0e0e0', padding: 0, boxShadow: 'none', background: 'white' }}>
                                                        <AdminGalleryCard
                                                            gallery={item}
                                                            onEdit={() => handleEditClick(item)}
                                                            onDelete={() => handleDeleteClick(item?._id)}
                                                        />
                                                    </div>
                                                </Grid>
                                            ))}</>
                                        }
                                    </Grid>
                                </div>
                            </div>
                        </>
                }
            </Grid>


            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}

            <Modal open={open} onClose={handleClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    overflowY: 'auto',
                    bgcolor: 'white',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}>
                    <h2 style={{ margin: 0, padding: 0 }}>{isEdit ? "Edit Gallery" : "Add Gallery"}</h2>

                    {formValues.image && (
                        <img
                            src={formValues?.image}
                            alt="Uploaded Preview"
                            style={{ marginTop: 20, width: '100%', borderRadius: 10 }}
                        />
                    )}

                    <TextField
                        fullWidth
                        label="city name"
                        name="city_name"
                        value={formValues?.city_name}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        style={{ boxShadow: 'none', padding: '2% 0', border: '1px dashed gray', color: 'black', marginTop: '2%', backgroundColor: 'white' }}
                    >
                        Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={handleImageUpload}
                        />
                    </Button>
                    {errors.image && <Typography color="error">{errors.image}</Typography>}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        style={{ marginTop: '2%', boxShadow: 'none', backgroundColor: primaryColor }}
                    >
                        {isEdit ? "Update" : "Save"}
                    </Button>

                </Box>
            </Modal>
        </>
    );
}
